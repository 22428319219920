import React from "react"
import { Styled } from "theme-ui"
import Page from "../components/page"
import SEO from "../components/seo"
import LindseyImg from "../../static/lindsey.jpg"

const About = () => {
  // @Note: Complete this form submission
  return (
    <Page>
      <SEO title="About" />
      <Styled.h1>About</Styled.h1>
      <img src={LindseyImg} width="100%" alt="About Lindsey" />
      <h3>
        Lindsey Baruch is a Los Angeles based food photographer, food stylist,
        recipe developer and video producer.
      </h3>
    </Page>
  )
}

export default About
